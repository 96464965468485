import request from '@/utils/request'

export function listGoods (query) {
  return request({
    url: '/wechat/wechat_mall_goods/',
    method: 'get',
    params: query
  })
}

export function deleteGoods (data) {
  return request({
    url: '/wechat/wechat_mall_goods/',
    method: 'delete',
    data
  })
}

/* // 创建页面获取商品列表
  export function getWxMallCommodityList (query) {
  return request({
    url: 'wechat/wechat_mall_commodity/list/',
    method: 'get',
    params: query
  })
} */

// 上架模糊获取商品列表私人
export function getWxMallCommodityList (query) {
  return request({
    url: '/wms/goods/',
    method: 'get',
    params: query
  })
}
// 上架模糊获取商品列表公共
export function getWxMallCommonCommodityList (query) {
  return request({
    url: '/wms/supervised_warehouse/goods/',
    method: 'get',
    params: query
  })
}

export function publishGoods (data) {
  return request({
    url: '/wechat/wechat_mall_goods/',
    method: 'post',
    data
  })
}

export function detailGoods (id) {
  return request({
    url: '/wechat/wechat_mall_goods/detail/',
    method: 'get',
    params: { id }
  })
}

export function editGoods (data) {
  return request({
    url: '/wechat/wechat_mall_goods/',
    method: 'put',
    data
  })
}

export function listCatAndBrand () {
  return request({
    url: '/goods/catAndBrand',
    method: 'get'
  })
}

export function listCat () {
  return request({
    url: '/wechat/category/name/',
    method: 'get'
  })
}
